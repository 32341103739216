<template>
  <v-card
    class="mx-auto"
    max-width="900"
    elevation="0"
    id="SocialLoginCard"
    color="rgb(0, 0, 0, 0)"
    :loading="loading || superLoading"
  >
    <v-row class="px-5">
      <!-- If Error Column -->
      <v-col
        v-if="login_error"
        cols="12"
        sm="12"
        class="d-flex align-center justify-center"
      >
        <v-alert type="error">
          {{ login_error }} <br /><br />
          Please refresh and try again :(
        </v-alert>
      </v-col>

      <!-- else Google Login Column -->
      <v-col
        v-else
        cols="12"
        sm="12"
        class="d-flex align-center justify-center"
      >
        <v-btn
          class="secondary--text grey lighten-3"
          :loading="loading || superLoading"
          :disabled="loading || superLoading"
          @click.prevent="signInWithGoogle"
          rounded
          outlined
          large
        >
          <v-img
            class="mx-auto"
            max-height="25px"
            :src="require(`../../../assets/auth/icons8-google-48.png`)"
            contain
          ></v-img>
          Login with Google &nbsp;&nbsp;&nbsp;
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "SocialLoginCard",
  props: ["superLoading"],
  data: () => ({
    loading: false,
    login_error: "",
  }),
  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },
    signInWithGoogle() {
      this.login_error = "";
      this.setLoading(true);
      this.$store
        .dispatch("signInWithGoogle")
        .then(() => {
          this.setLoading(false);
          this.$emit("loginSuccessful");
        })
        .catch((error) => {
          this.login_error = error;
          if (error.code == "auth/popup-closed-by-user") {
            this.login_error = "";
          }
          this.setLoading(false);
        });
    },
  },
};
</script>
