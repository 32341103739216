<template>
  <div id="register">
    <v-container fluid fill-height class="content">
      <v-row align="center">
        <!-- Greeting Column -->
        <v-col sm="12" md="5" class="mx-auto">
          <h1 class="text-center mb-8 text-h5 montserrat-font px-5">
            <i>
              " You don’t have to be great to start. But you have to start to be
              great. "
            </i>
          </h1>
          <v-img
            class="mx-auto hidden-sm-and-down"
            style="max-height: 40vh"
            :src="require(`../../assets/auth/undraw_Partying_re_at7f.svg`)"
            contain
          ></v-img>
        </v-col>

        <!-- Register Column -->
        <v-col sm="12" md="7" class="mx-auto">
          <RegisterCard
            :superLoading="superLoading"
            @setSuperLoading="setSuperLoading"
            @registerSuccessful="redirectToNextUrl"
          />

          <!-- 'OR' text row -->
          <v-row class="my-4" justify="center" align="center">
            <v-col class="d-flex justify-center">
              <v-divider></v-divider>
            </v-col>
            <v-col class="d-flex justify-center text--disabled"> OR </v-col>
            <v-col class="d-flex justify-center">
              <v-divider></v-divider>
            </v-col>
          </v-row>

          <SocialLoginCard
            :superLoading="superLoading"
            @setSuperLoading="setSuperLoading"
            @loginSuccessful="redirectToNextUrl"
          />
        </v-col>
      </v-row>
    </v-container>

    <!-- Scaffolding for animated background -->
    <div class="animated-bg">
      <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  </div>
</template>

<script>
import RegisterCard from "@/components/Auth/Register/RegisterCard.vue";
import SocialLoginCard from "@/components/Auth/Login/SocialLoginCard.vue";

export default {
  name: "Register",
  metaInfo: {
    title: "Register",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Practice more than 10,000 exam based questions, Start your prepration now with plusminus mock tests and Test series.",
      },
      {
        vmid: "keywords",
        name: "keywords",
        content:
          "Free Mock Tests, Test Series, Online Mock tests. Previous year paper, solutions, DSSSB questions.",
      },
      {
        vmid: "robots",
        name: "robots",
        content: "index, follow",
      },
    ],
  },
  components: {
    RegisterCard,
    SocialLoginCard,
  },
  data: () => ({
    superLoading: true,
    nextUrl: "/dashboard",
  }),
  methods: {
    setSuperLoading(value) {
      this.superLoading = value;
    },
    redirectToNextUrl() {
      this.$router.push(this.nextUrl);
    },
  },
  mounted() {
    this.superLoading = false;
    const nextUrl = this.$route.query.nextUrl;
    if (nextUrl) {
      this.nextUrl = nextUrl;
    }
  },
};
</script>


<style>
/*BG Animation related CSS below */
.content {
  z-index: 2;
  position: absolute;
}

.animated-bg {
  z-index: 1;
  width: 100vw;
  height: 90vh;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #fc5c7d; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #6a82fb,
    #fc5c7d
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #6a82fb,
    #fc5c7d
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 21s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 0.3;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
</style>